import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ["full_text_search", "citation_finder"]

  connect () {
    super.connect()
  }

  loadSectionsAccordion() {
    var $this = this, parent_id = $this.element.dataset["sectionParentId"],
        params = { per_page: 1000 }, 
        opts = { parent_accordion_id: $this.element.dataset["accordionId"], accordionTarget: $this.element.dataset["accordionTarget"],
                 accordionFor: $this.element.dataset["accordionFor"] }

    if (parent_id !== null && parent_id !== undefined && parent_id !== "") {
      params.parent_id = parent_id
    } else {
      params.is_root = true
    }

    if ($(opts.accordionTarget).find(".accordion-flush").length === 0) {
      $this.stimulate("SectionsReflex#render_sections_accordion", 
                      $this.element.dataset["libraryPuid"], $this.element.dataset["letter"], params, opts)
    }
  }
}
