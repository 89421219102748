(function ($) {
  'use strict';
  $.fn.footnotes = function () {
    var a, b, c;
    a = $(this), b = a.find('a[href^="#footnote"]')
    c = a.find('p>span, p>a[href^="#fn"]>span').filter(function() {
      return ($(this).css('color') == 'rgb(255, 0, 0)')
    })

    b.each(function(){
      var $this = $(this);
      if($this.anchor_check()){
        if(!$this.parent('span.footnote-x').length){
          // Wrap the anchor with 'footnote-x'
          $this.wrap('<span class="footnote-x"></span>')
        }
      }
    })
    // Append index to every anchor
    b = a.find('span.footnote-x a[href^="#footnote"]')
    b.each(function(){
      var $this = $(this);
      var $matches = $($this.prop("tagName") + '#' + $this.attr("id") + ':not([data-index])')
      var index = 0;
      $matches.each(function(){
        $(this).attr('id', $(this).attr('id') + '_' + index)
        $(this).attr('name', $(this).attr('name') + '_' + index)
        $(this).attr('href', $(this).attr('href') + '_' + index)
        $(this).attr('data-index', index)
        index = index + 1;
      })
    })
    c.each(function(){
      var $this = $(this)
      $this.footnote_check()
    })
    c = a.find('a[href^="#fn"]')
    c.each(function(){
      var $this = $(this);
      var $matches = $($this.prop("tagName") + '#' + $this.attr("id") + ':not([data-index])')
      var index = 0;
      $matches.each(function(){
        var self, parent
        self = $(this), parent = self.parent()
        self.closest('p').find('#cdasiatab').detach()
        self.attr('id', self.attr('id') + '_' + index)
        self.attr('name', self.attr('name') + '_' + index)
        self.attr('href', self.attr('href') + '_' + index)
        self.attr('data-index', index)
        parent.is('a') ? parent.replaceWith(self) : parent.closest('a').replaceWith(parent) // Remove parent its parent 'a' tag
        index = index + 1;
      })
    })
  }
  $.fn.anchor_check = function () {
      var a, b, c, d;
      a = $(this), b = a.attr('href'), c = $.trim(a.text())
      if(!$.isNumeric($.trim(c)) && c != '*' && c != 'n'){return false}
      if($.trim(b) && a.is('[href^="#footnote"]')){
        // Check whether href, name and text is valid
        var a_href_id = a.attr('href').substring(9) // Get only the value after '#footnote'
        var a_name_id = a.attr('name') ? a.attr('name').substring(2) : a_href_id // Get only the value after 'fn' if name attr is present if not return a_href_id
        var a_id = a.attr('id') ? a.attr('id').substring(2) : ''
        if(a_href_id != a_name_id || a_href_id != c || a_id != c || a_name_id != c){
            a.attr('href', '#footnote' + c), a.attr('id', 'fn' + c), a.attr('name', 'fn' + c)
        }
      }else{
        // If anchor to footnote is invalid by href=#footnote*** or no href
        // the text inside the 'a' tag will use as a reference
        a.attr('href', '#footnote' + c), a.attr('id', 'fn' + c), a.attr('name', 'fn' + c)
      }
      return true;
  }
  $.fn.footnote_check = function () {
    var a, b, c, d;
    a = $(this), d = null
    if(a.find('a:not([href^="#footnote"])').length){
      a.find('a').each(function(){
        b = $.trim($(this).text()), c = b.match(/([\*]{1,3}|[\d]{1,3})/)
        if(c!= null){
          c = c[0]
          d = $(document.createElement('a'))
          d.text(b), d.attr('href', '#fn' + c), d.attr('id', 'footnote' + c), d.attr('name', 'footnote' + c), d.addClass('m-r-lg'), d.css('color', 'red')
          a.html(''), a.append(d)
          return true;
        }
      })
      if(d=null){
        return false
      }
    }else if(!a.find('a').length){
      b = $.trim(a.text()), c = b.match(/([\*]{1,3}|[\d]{1,3})/)
      if(c!= null){
        c = c[0]
        d = $(document.createElement('a'))
        d.text(b), d.attr('href', '#fn' + c), d.attr('id', 'footnote' + c), d.attr('name', 'footnote' + c), d.addClass('m-r-lg'), d.css('color', 'red')
        a.html(''), a.append(d)
      }else{
          return false
      }
    }
    return true
  }
  $.fn.footnote = function () {
    var a = $(this)
    // Validation of footnote
    var b, c, d;
    b = $(a.attr('href'))
    if(b.length<=0 && a.data('index')>0){
      var a_id = a.attr('id').substring(0, a.attr('id').indexOf('_'))
      var a_like = $('a[href^="#footnote"][id^="' + a_id + '"]')
      var a_found = false;
      a_like.each(function(){
          if(!a_found){b = $($(this).attr('href'))}
          a_found = b.length>0
      })
    }
    if(b.length>0){
      c = b.closest('p')
      d = c.footnote_text(b.parent('span') || b), d = d.substring(0,499) + (d.length > 500 ? '...' : '')
      a.attr('data-original-title', d), a.attr('data-toggle', 'tooltip')
      a.tooltip({title: d}), a.tooltip('show')
    }else{
      // typeof a._errorReport === "function" && a._errorReport({
      //     type: 'footnote',
      //     details: 'Footnote not found @ ' + a.attr('href')
      // })
    }
    return a
  }
  $.fn.footnote_text = function (except) {
    var a = '';
    $(this).children().not(except).each(function(){
      // Check first if the current select element not equal to except
      var self = $(this)
      a += self.text()
    })
    return a;
  }
  $(document).on('mouseover mouseenter focus', 'a[href^="#footnote"]', function(event){
    event.preventDefault()
    $(this).footnote()
  })
}(jQuery))
