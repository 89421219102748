import ApplicationController from './application_controller'
export default class extends ApplicationController {
  static targets = ["full_text_search", "citation_finder"]

  connect () {
    super.connect()
  }

  search () {
    var $this = this, params = { q: $this.full_text_searchTarget.value, page: 1 },
        opts = { accordionTarget: $this.element.dataset["accordionTarget"] }

    $.get("/api/subjects.json", params, function (search_results, status) {
      if (status === "success") {
        $this.stimulate("SubjectsReflex#render_law_subjects_offcanvas_accordion", search_results, opts)
      }
    })
  }

  search_by_letters () {
    var $this = this, params = { letter: $this.element.dataset["letter"], library_uid: $this.element.dataset["libraryUid"], page: 1 },
        opts = { accordionTarget: $this.element.dataset["accordionTarget"] }

    $.get("/api/subjects/roots_by_letter.json", params, function (search_results, status) {
      if (status === "success") {
        $this.stimulate("SubjectsReflex#render_law_subjects_offcanvas_accordion", search_results, opts)
      }
    })
  }

  loadSubjectsAccordion () {
    var $this = this, parent_id = $this.element.dataset["subjectParentId"],
        params = { per_page: 1000 }, 
        opts = { parent_accordion_id: $this.element.dataset["accordionId"], accordionTarget: $this.element.dataset["accordionTarget"],
                 accordionFor: $this.element.dataset["accordionFor"] }

    if (parent_id !== null && parent_id !== undefined && parent_id !== "") {
      params.parent_id = parent_id
    } else {
      params.is_root = true
    }

    if ($(opts.accordionTarget).find(".accordion-flush").length === 0) {
      $this.stimulate("SubjectsReflex#render_subjects_accordion", 
                      $this.element.dataset["libraryPuid"], $this.element.dataset["letter"], params, opts)
    }
  }
}
