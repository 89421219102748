import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';

$(document).ready(function(){
    $('.owl-carousel').owlCarousel({ 
		items:1,
		loop:true,
		autoplay:true,
		autoplayTimeout:5000,
		autoplayHoverPause:false,
		animateOut: 'fadeOut',
		animateIn: 'fadeIn',
		margin:30,
		stagePadding:30,
		smartSpeed:450
		});
});
