import ApplicationController from './application_controller'

export default class extends ApplicationController {

  connect () {
    super.connect()
  }

  loadContentTypes () {
    var public_uid = this.element.dataset["publicUid"]
    
    if ($("#tabContentTypes").find(".container-sm").length === 0) {
      this.stimulate("DocumentsReflex#render_document_content_types_tab", public_uid)
    }
  }

  loadSubjects () {
    var public_uid = this.element.dataset["publicUid"]
    
    if ($("#tabSubjects").find(".container-sm").length === 0) {
      this.stimulate("DocumentsReflex#render_document_subjects_tab", public_uid)
    }
  }

  loadSections () {
    var public_uid = this.element.dataset["publicUid"]
    
    if ($("#tabTopics").find(".container-sm").length === 0) {
      this.stimulate("DocumentsReflex#render_document_sections_tab", public_uid)
    }
  }
}
