import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect() {
    super.connect()

    var default_opts = {
      plugins: ['restore_on_backspace', 'remove_button'],
      searchField: ['name', 'optgroup'],
      valueField: "id",
      allowEmptyOption: false,
      closeAfterSelect: true,
      showEmptyOptionInDropdown: true,
      emptyOptionLabel: true,
      hideSelected: true,
      placeholder: "Please Select"
    }

    $(".default-selectize").selectize(default_opts);
    
    var default_multiple_opts = $.extend(default_opts, { closeAfterSelect: false })
    $(".default-multiple-selectize").selectize(default_multiple_opts);

    var subjects_selectize_opts = {
      load: function (query, callback) {
        $.get("/api/subjects.json", { q: query, per_page: 1000 }, function (data, status) {
          if (status === 'success') {
            callback(data);
          }
        })
      }
    }

    var $subjects_selectize = $(".subjects-selectize").selectize($.extend(subjects_selectize_opts, default_opts))
    var $subjects_multiple_selectize = $(".subjects-multiple-selectize").selectize($.extend(subjects_selectize_opts, default_multiple_opts))

    $.get("/api/subjects.json", { per_page: 100 }, function (results, status) {
      if (status === 'success') {
        $.each(results, function (i, result) {
          if ($subjects_selectize.length) $subjects_selectize[0].selectize.addOption(result, true);
          if ($subjects_multiple_selectize.length) $subjects_multiple_selectize[0].selectize.addOption(result, true);
        })

        //this code will be autoFocus on the first selectize
        // if ($subject_ids_selectize.length) $subject_ids_selectize[0].selectize.refreshOptions();
        // if ($subjects_selectize.length) $subjects_selectize[0].selectize.refreshOptions();
      }
    });

    var sections_selectize_opts = {
      load: function (query, callback) {
        $.get("/api/sections.json", { q: query, per_page: 1000 }, function (data, status) {
          if (status === 'success') {
            callback(data);
          }
        })
      }
    }

    var $sections_single_selectize = $(".sections-single-selectize").selectize($.extend(sections_selectize_opts, default_opts))
    var $sections_multiple_selectize = $(".sections-multiple-selectize").selectize($.extend(sections_selectize_opts, default_multiple_opts))

    $.get("/api/sections.json", { per_page: 100 }, function (results, status) {
      if (status === 'success') {
        $.each(results, function (i, result) {
          if ($sections_single_selectize.length) $sections_single_selectize[0].selectize.addOption(result, true);
          if ($sections_multiple_selectize.length) $sections_multiple_selectize[0].selectize.addOption(result, true);
        })

        //this code will be autoFocus on the first selectize
        // if ($subject_ids_selectize.length) $subject_ids_selectize[0].selectize.refreshOptions();
        // if ($subjects_selectize.length) $subjects_selectize[0].selectize.refreshOptions();
      }
    });

    var content_types_selectize_opts = {
      load: function (query, callback) {
        $.get("/api/content_types.json", { q: query, per_page: 1000 }, function (data, status) {
          if (status === 'success') {
            callback(data);
          }
        })
      }
    }

    var $content_types_single_selectize = $(".content-types-single-selectize").selectize($.extend(content_types_selectize_opts, default_opts))
    var $content_types_multiple_selectize = $(".content-types-multiple-selectize").selectize($.extend(content_types_selectize_opts, default_multiple_opts))

    $.get("/api/content_types.json", { per_page: 100 }, function (results, status) {
      if (status === 'success') {
        $.each(results, function (i, result) {
          if ($content_types_single_selectize.length) $content_types_single_selectize[0].selectize.addOption(result, true);
          if ($content_types_multiple_selectize.length) $content_types_multiple_selectize[0].selectize.addOption(result, true);
        })

        //this code will be autoFocus on the first selectize
        // if ($subject_ids_selectize.length) $subject_ids_selectize[0].selectize.refreshOptions();
        // if ($subjects_selectize.length) $subjects_selectize[0].selectize.refreshOptions();
      }
    });
  }
}
